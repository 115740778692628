var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resetpassword-page-container"},[_c('div',{staticClass:"bcm-container mt-4_1"},[_c('b-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"md":"10"}},[_c('h5',{staticClass:"title content-title text-center"},[_vm._v(_vm._s(_vm.$t('setNewPassword')))]),_c('form',{staticClass:"reset-form account-content__body container-fluid",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"groupPassword","label-for":"password"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:8|passwordM'),expression:"'required|min:8|passwordM'"}],ref:"pw_confirm",attrs:{"name":"password","type":"password","autofocus":true,"showHidePass":true,"autocomplete":"new-password","placeholder":_vm.$t('Password'),"validations":[
                    {
                      condition: _vm.errors.has('password'),
                      text: _vm.errors.first('password')
                    }
                  ]},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"groupPasswordConfirm","label-for":"passwordConfirm"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|confirmed:pw_confirm'),expression:"'required|confirmed:pw_confirm'"}],attrs:{"name":"passwordConfirm","type":"password","autocomplete":"new-password","showHidePass":true,"placeholder":_vm.$t('Confirm Password'),"validations":[
                    {
                      condition: _vm.errors.has('passwordConfirm'),
                      text: _vm.errors.first('passwordConfirm')
                    }
                  ]},model:{value:(_vm.form.passwordConfirm),callback:function ($$v) {_vm.$set(_vm.form, "passwordConfirm", $$v)},expression:"form.passwordConfirm"}})],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.serverValidation),expression:"serverValidation"}],staticClass:"form-errors is-invalid"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.serverValidation)}})]),_c('div',{staticClass:"block-submit mt-5"},[_c('button',{staticClass:"btn btn-primary btn-submit",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('Submit')))])])],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }